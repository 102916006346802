<template>
  <div class="positionT0L0">
    <p class="title">基本信息</p>
    <div class="teacher-form">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="{ span: 2 }"
        :wrapper-col="{ span: 12 }"
        labelAlign="left"
      >
        <a-form-model-item label="账号" prop="userName">
          <a-input
            v-model="form.userName"
            placeholder="请输入对应账号的邮箱地址"
          />
        </a-form-model-item>
        <a-form-model-item label="姓名" prop="fullName">
          <a-input v-model="form.fullName" placeholder="请输入姓名" />
        </a-form-model-item>
        <a-form-model-item label="职称" prop="title">
          <a-input v-model="form.title" placeholder="请输入职称" />
        </a-form-model-item>
        <a-form-model-item label="教师标签" prop="labels">
          <a-select
            v-model="form.labels"
            mode="multiple"
            placeholder="请选择标签"
            :filterOption="filterOption"
            @change="handleChange"
            :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            "
          >
            <a-select-option v-for="item in tabSelectList" :key="item.labelNo">
              {{ item.labelText }}
            </a-select-option>
          </a-select>
          <span class="tag-label-refresh" @click="onRefresh">刷新</span>
          <span class="tag-label-page" v-show="isShowTeacherTag">
            <router-link tag="a" target="_blank" to="/teachersManage/teacherTag"
              >前往标签管理<a-icon type="right" :style="{ fontSize: '10px' }"
            /></router-link>
          </span>
        </a-form-model-item>
        <a-form-model-item
          label="头像"
          class="upload_img"
          required
          :class="{ 'has-error': !form.pictureUrl && isPictureUrl }"
        >
          <CropperUpload
            :imageUrl="form.pictureUrl"
            :options="pictureOptions"
            @avatarfn="pictureUrlFn"
            avatarTextTop="建议尺寸90*90px，JPG.PNG.JPEG格式"
          />
          <div class="ant-form-explain" v-if="!form.pictureUrl && isPictureUrl">
            请选择头像
          </div>
        </a-form-model-item>
        <a-form-model-item
          label="封面图"
          class="upload_img"
          required
          :class="{ 'has-error': !form.coverUrl && isCoverUrl }"
        >
          <CropperUpload
            :imageUrl="form.coverUrl"
            :options="coverOptions"
            @avatarfn="coverUrlFn"
            avatarTextTop="建议尺寸270*330px，JPG、PNG、JPEG格式"
          />
          <div class="ant-form-explain" v-if="!form.coverUrl && isCoverUrl">
            请选择封面图
          </div>
        </a-form-model-item>
        <a-form-model-item label="一句话介绍" prop="conclusion">
          <a-input
            v-model="form.conclusion"
            :maxLength="12"
            placeholder="请输入一句话介绍"
          >
            <div slot="addonAfter">{{ form.conclusion.length }}/12</div>
          </a-input>
        </a-form-model-item>
        <a-form-model-item
          label="教师详情"
          prop="description"
          class="description"
          :wrapper-col="{ span: 20 }"
        >
          <u-editor :content="form.description" @editorFn="briefFn"></u-editor>
        </a-form-model-item>
      </a-form-model>
    </div>
    <footer-tool-bar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="() => $router.go(-1)"
        >返回</a-button
      >
      <a-button type="primary" @click="keepClick" :loading="isDisabled"
        >保存</a-button
      >
    </footer-tool-bar>
  </div>
</template>

<script>
// 安装 UE： npm i vue-ueditor-wrap or npm i vue-ueditor-wrap@2.x
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import CropperUpload from "@/components/file/CropperUpload";
import UEditor from "@/components/ueditor/ueditor";
import {
  TagSelectApi,
  TeacherDetailApi,
  SaveTeacherApi,
  UpdTeacherApi,
} from "@/request/api/teachersManage";
import { message } from "ant-design-vue";
import { throttle, codeFn } from "../../utils/tools";
export default {
  mixins: [baseMixin],
  components: { FooterToolBar, CropperUpload, UEditor },
  data() {
    return {
      isDisabled: false, // 保存的按钮是否禁用
      form: {
        id: this.$route.params.id == 0 ? "" : this.$route.params.id,
        userName: "",
        fullName: "",
        title: "",
        labels: [],
        pictureUrl: "",
        pictureCosKey: "",
        coverUrl: "",
        coverCosKey: "",
        conclusion: "",
        description: "",
      },
      rules: {
        userName: [
          {
            required: true,
            message: "请输入对应账号的邮箱地址",
            trigger: "blur",
          },
          {
            pattern:
              /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
            message: "邮箱格式不正确",
            trigger: "blur",
          },
        ],
        fullName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { max: 4, message: "最多输入4个字符", trigger: "blur" },
        ],
        title: [
          { required: true, message: "请输入职称", trigger: "blur" },
          { max: 12, message: "最多输入12个字符", trigger: "blur" },
        ],
        labels: [{ required: true, message: "请选择标签", trigger: "change" }],
        conclusion: [
          { required: true, message: "请输入一句话介绍", trigger: "blur" },
          { max: 12, message: "最多输入12个字符", trigger: "blur" },
        ],
        description: [{ required: true }],
      },
      tabSelectList: [],
      isPictureUrl: false, // 判断是否上传头像
      isCoverUrl: false, // 判断是否封面图
      isShowTeacherTag: false, // 是否显示跳转到老师标签页
      pictureOptions: {
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 90, //默认生成截图框宽度
        autoCropHeight: 90, //默认生成截图框高度
        fixedBox: true, //是否固定截图框大小 不允许改变
        previewsCircle: false, //预览图是否是原圆形
        title: "修改图片",
      },
      coverOptions: {
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 270, //默认生成截图框宽度
        autoCropHeight: 330, //默认生成截图框高度
        fixedBox: true, //是否固定截图框大小 不允许改变
        previewsCircle: false, //预览图是否是原圆形
        title: "修改图片",
      },
    };
  },
  created() {
    this.TagSelectApi();
    let id = this.$route.params.id;
    if (id != 0) {
      TeacherDetailApi({ id: id }).then(({ code, data }) => {
        if (code == 200) {
          let formData = data;
          this.form.userName = formData.userName;
          this.form.fullName = formData.fullName;
          this.form.title = formData.title;
          this.form.labels = formData.adminLabels;
          this.form.pictureUrl = formData.pictureUrl;
          this.form.pictureCosKey = formData.pictureCosKey;
          this.form.coverUrl = formData.coverUrl;
          this.form.coverCosKey = formData.coverCosKey;
          this.form.conclusion = formData.conclusion;
          this.form.description = formData.description;
        }
      });
    }
    if (codeFn("teacherTag")) this.isShowTeacherTag = true;
  },
  methods: {
    // 标签下拉框列表
    TagSelectApi() {
      TagSelectApi({ type: 2 }).then((res) => {
        if (res.code == 200) {
          this.tabSelectList = res.data;
        }
      });
    },
    // 获取标签选择的值
    handleChange(value) {
      this.form.labels = value;
    },
    // 将输入的内容与显示的内容进行匹配
    filterOption(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0;
    },
    // 标签刷新事件
    onRefresh() {
      this.form.labels = [];
      throttle(this.TagSelectApi());
    },
    // 前往标签列表页面
    navToTag() {
      this.$router.push("/teachersManage/teacherTag");
    },
    // 获取头像链接
    pictureUrlFn(imgData) {
      this.form.pictureUrl = imgData.url;
      this.form.pictureCosKey = imgData.cosKey;
    },
    // 获取封面图链接
    coverUrlFn(imgData) {
      this.form.coverUrl = imgData.url;
      this.form.coverCosKey = imgData.cosKey;
    },
    // 获取教师详情
    briefFn(desc) {
      this.form.description = desc;
    },
    // 保存
    keepClick() {
      this.isPictureUrl = this.form.pictureUrl == "" ? true : false;
      this.isCoverUrl = this.form.coverUrl == "" ? true : false;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.isDisabled = true;
          if (this.$route.params.id != 0) {
            UpdTeacherApi(this.form).then((res) => {
              if (res.code === 200) {
                message.success("编辑成功");
                this.$router.push("/teachersManage/teacherList");
              }
              this.isDisabled = false;
            });
          } else {
            SaveTeacherApi(this.form).then((res) => {
              if (res.code === 200) {
                message.success("新增成功");
                this.$router.push("/teachersManage/teacherList");
              }
              this.isDisabled = false;
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 16px;
  color: #000;
  &::before {
    content: "";
    border-left: 3px solid #1890ff;
    margin-right: 8px;
    padding: 2px 0;
  }
}
.teacher-form {
  margin-top: 30px;
}
/deep/.ant-form-item-children {
  display: flex;
  align-items: center;
}
.tag-label-refresh {
  position: absolute;
  right: -50px;
  color: #1890ff;
  cursor: pointer;
}
.tag-label-page {
  position: absolute;
  right: -170px;
  color: #1890ff;
  cursor: pointer;
}
.conclusion-label-num {
  position: absolute;
  right: 10px;
}
/deep/.description .ant-form-item-control {
  line-height: unset;
}
/deep/ .upload_img .ant-form-item-children {
  display: block;
}
</style>
