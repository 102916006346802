// 师资管理相关接口
import request from '@/request/request'

// 教师列表
export const TeacherListApi = (params) => request.post('/admin/course/teacher/pageTeacherResult', params);
// 删除教师
export const TeacherDelApi = (params) => request.get('/admin/course/teacher/delTeacher', { params });
// 新增老师
export const SaveTeacherApi = (params) => request.post('/admin/course/teacher/saveTeacher', params);
// 修改老师
export const UpdTeacherApi = (params) => request.post('/admin/course/teacher/updTeacher', params);

// // 新增or编辑教师
// export const TeacherOptionApi = (params) => request.post('/admin/course/teacher/saveOrUpdTeacher', params);
// 教师详情
export const TeacherDetailApi = (params) => request.get('/admin/course/teacher/getTeacher', { params });
// 标签列表
export const TagListApi = (params) => request.post('/admin/course/label/showLabelsByType', params);
// 新增标签
export const SaveLabelApi = (params) => request.post('/admin/course/label/saveLabel', params);
// 修改标签
export const UpdLabelApi = (params) => request.post('/admin/course/label/updLabel', params);
// 删除标签
export const TagDelApi = (params) => request.get('/admin/course/label/delLabel', { params });
// 下拉框标签列表
export const TagSelectApi = (params) => request.get('/admin/course/label/select/getAllLabelsByType', { params });